
import { validateForms } from './../functions/validate-forms.js';
const rules1 = [
  {
    ruleSelector: '.input-area',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните поле!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm = () => {
  alert('Спасибо за заявку! В ближайшее время с вами свяжутся.')
};

validateForms('.modal-form', rules1, afterForm);
